<template>
    <div>
        <div class="text">是否要对以下订单进行强制截单并上账？</div>
        <div v-for="(item, index) in formConfig.row" :key="index">
            <div>{{item.orderCode}}</div>
        </div>
        <div class="dialog-footer">
            <el-button icon="el-icon-check" type="primary" @click="submit"
            >确认</el-button
            >
            <el-button icon="el-icon-close" type="danger" @click="cancel"
            >关闭</el-button
            >
        </div>
    </div>
</template>
<script>
import request from '@/found/utils/request';

export default {
  data() {
    return {
      params: {},
    };
  },
  props: {
    formConfig: Object,
  },
  methods: {
    submit() {
      request.post('/dms/dmstransitorderdetail/mandatoryStatement', this.formConfig.selection).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
    cancel() {
      this.$emit('onClose');
    },
  },
};
</script>
<style lang="less" scoped>

</style>
