import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import DetailForm from './detail_form';

export default {
  extends: TablePage,
  components: {
    DetailForm,
  },
  data() {
    return {
      formConfig: {},
    };
  },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = { demandRequestLineCode: this.propsObj.row.lineNo.toString(), demandRequestCode: this.propsObj.row.orderCode };
    this.getConfigList('delivery_form_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'particulars') {
        this.formName = 'DetailForm';
        this.modalConfig.title = '发货单详情';
        this.propsObjInData.row = row;
        this.modalConfig.width = '80%';
        this.openDrawer();
      }
    },
  },
};
