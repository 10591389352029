<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import AddForm from './form';
import MandatoryForm from './form/mandatory_form.vue';
import DeliveryForm from './form/delivery_form';

export default {
  extends: TablePage,
  components: {
    AddForm,
    MandatoryForm,
    DeliveryForm,
  },
  data() {
    return {
      formConfig: {},
      searchFormData: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('terminal_orders_list');
  },
  methods: {
    modalClick({ val, row }) {
      console.log(val.code);
      if (val.code === 'add') {
        this.formName = 'AddForm';
        this.modalConfig.title = '新增';
        this.modalConfig.width = '80%';
        this.openDrawer();
      }
      if (val.code === 'mandatory_billing') {
        if (this.selection.length > 0) {
          this.formName = 'MandatoryForm';
          this.modalConfig.title = '强制截单';
          this.modalConfig.width = '300px';
          this.modalConfig.height = '250px';
          this.formConfig.row = row;
          this.formConfig.code = val.code;
          this.formConfig.selection = this.selection;
          this.openModal();
        } else {
          this.$message('请至少选择一条数据！');
          return false;
        }
      }
      if (val.code === 'delivery_note_information') {
        this.formName = 'DeliveryForm';
        this.modalConfig.title = '发货单信息';
        this.propsObjInData.row = row;
        this.modalConfig.width = '80%';
        this.openDrawer();
      }
    },
  },
};
</script>
