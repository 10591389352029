import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  extends: TablePage,
  components: {},
  data() {
    return {
      formConfig: {},
    };
  },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = { shipmentLineItemNumber: this.propsObj.row.shipmentLineItemNumber, shipmentNumber: this.propsObj.row.shipmentNumber };
    this.getConfigList('detail_form_list');
  },
  methods: {
    // 搜索事件前置事件
    beforeSearchEvent() {
      this.searchFormData.shipmentLineItemNumber = this.propsObj.row.shipmentLineItemNumber;
      this.formData.shipmentLineItemNumber = this.propsObj.row.shipmentLineItemNumber;
      this.searchFormData.shipmentNumber = this.propsObj.row.shipmentNumber;
      this.formData.shipmentNumber = this.propsObj.row.shipmentNumber;
      return true;
    },
  },
};
