<template>
  <div class="list-box">
    <el-row>
      <el-col :span="20" :offset="1">
        <vxe-toolbar v-if="notView">
          <template v-slot:buttons>
            <vxe-button @click="addTable('')">添加</vxe-button>
            <vxe-button @click="deleteTable('first')">清空</vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign"
                   @checkbox-all="({records}) => selectAllEvent('tableSelect',records)"
                   @checkbox-change="({records}) => selectChangeEvent('tableSelect',records)"
                   :data="tableData">
          <vxe-table-column type="index" title="行号"
                            width="50"></vxe-table-column>
          <vxe-table-column field="productCode" title="商品编码"></vxe-table-column>
          <vxe-table-column field="productName" title="商品名称"></vxe-table-column>
          <vxe-table-column field="productPrice" title="单价"></vxe-table-column>
          <vxe-table-column field="spec" title="规格"></vxe-table-column>
          <vxe-table-column field="totalNum" title="数量" :cell-render="{name: '$input', props: {type: 'integer'}, events: {blur: this.totalNumInput}} " ></vxe-table-column>
          <vxe-table-column field="inventoryNum" title="库存"></vxe-table-column>
        </vxe-table>
      </el-col>
    </el-row>

    <SelectConfig ref="selectConfig"
                  @onGetSelect="onGetSelect" />
  </div>

</template>

<script>
import { Loading } from 'element-ui';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';
import request from '../../../../../../../utils/request';
import Storage from '../../../../../../../utils/storage';

export default {
  name: 'SelectProduct',
  components: {
    SelectConfig,
  },
  props: {
    value: Array,
    notView: Boolean,
    transitDealerCode: String,
    transitDealerName: String,
  },
  watch: {
    value() {
      this.tableData = this.value || [];
    },
    transitDealerCode(val) {
      console.log(val);
    },
    transitDealerName(val) {
      console.log(val);
    },
  },
  data() {
    return {
      modalConfig: {}, // 弹框信息
      formConfig: {
        tableUrl: '',
      }, // 弹框数据
      formName: 'Form',
      activeName: 'first',
      allAlign: null,
      tableData: [],
      modalType: '', // 弹框包含非包含
      tabType: 'first',
      tableSelect: [],
      numList: [],
    };
  },
  methods: {
    totalNumInput(val) {
      console.log(val);
      const data = JSON.parse(JSON.stringify(this.tableData));
      const { row, rowIndex } = val;
      data[rowIndex].totalNum = row.totalNum;
      if (Number(row.totalNum) % row.unitConversion) {
        this.$message.error('请输入规格的整数倍');
        data[rowIndex].totalNum = 0;
      }
      this.$set(this, 'tableData', data);
      this.$emit('input', this.tableData);
    },
    addTable(val) {
      const selectionList = [];
      const list = this.tableData;
      list.forEach((v) => {
        selectionList.push(v.productCode);
      });
      const params = {
        functionCode: 'select_product_modal',
        data: list,
        selectionList,
        idKey: 'productCode',
        paramData: {
          transitDealerCode: this.transitDealerCode,
        },
      };
      this.$refs.selectConfig.openSelectModal(params);

      // this.openModal();
    },
    async onGetSelect(val) {
      let data = val;
      let inventoryQuantity = null;
      const custList = JSON.parse(Storage.l.get('custList'));
      inventoryQuantity = (custList || []).find((v) => v.dictCode === this.transitDealerCode);
      if (inventoryQuantity) {
        const productCodes = [];
        data.forEach((v) => {
          if (v.productCode === '245011220259071') {
            productCodes.push(v.productCode);
          }
        });
        if (productCodes.length > 0) {
          const loadingInstance = Loading.service({
            fullscreen: true,
            text: '加载中...',
            background: 'rgba(0, 0, 0, 0.8)',
          });
          request
            .post('/dms/transitOrder/inventoryInquiryQuery', {
              productCodes,
              transitDealerCode: this.transitDealerCode,
              transitDealerName: this.transitDealerName,
            })
            .then((res) => {
              this.$nextTick(() => {
                loadingInstance.close();
              });
              if (!res.success) return this.$message.error(res.message);
              this.numList = res.result;
              data = data.map((item) => {
                const obj = item;
                obj.inventoryNum = ((this.numList || []).find((v) => v.productCode === obj.productCode) || {}).inventoryNum || '系统未对接';
                return obj;
              });
              this.tableData = data;
              this.$emit('input', this.tableData);
            }).catch((err) => {
              this.$nextTick(() => {
                loadingInstance.close();
              });
            });
        } else {
          data = data.map((v) => {
            const obj = v;
            obj.inventoryNum = '系统未对接';
            return obj;
          });
          this.tableData = data;
          this.$emit('input', this.tableData);
        }
      } else {
        data = data.map((v) => {
          const obj = v;
          obj.inventoryNum = '系统未对接';
          return obj;
        });
        this.tableData = data;
        this.$emit('input', this.tableData);
      }
    },
    deleteTable(val) {
      this.tableData = [];
      this.tableSelect = [];
      this.$emit('input', []);
    },
    selectChangeEvent(val, records) {
      this[val] = records;
    },
    selectAllEvent(val, records) {
      this[val] = records;
    },
    // 打开弹窗
    openModal() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Modal' },
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
</style>
