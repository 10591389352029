var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "text" }, [
        _vm._v("是否要对以下订单进行强制截单并上账？"),
      ]),
      _vm._l(_vm.formConfig.row, function (item, index) {
        return _c("div", { key: index }, [
          _c("div", [_vm._v(_vm._s(item.orderCode))]),
        ])
      }),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-check", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", type: "danger" },
              on: { click: _vm.cancel },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }